.screen__home {
  &__image-area {
    .screen__home__main-title {
      width: 100%;
      text-align: center;
      font-size: 5rem;
      margin: 0;
      padding: 50px 0 30px 0;

      @include media-breakpoint-down(md) {
        font-size: 3rem;
      }
    }

    .screen__home__image-area__image-wrapper {
      position: relative;

      .screen__home__main-image {
        display: block;
        margin: auto;
        width: 100%;
      }

      .screen__home__secondary-image__wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 25%;
        width: 50%;
        background: rgba(#fff, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        .screen__home__secondary-image {
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }
    }
  }

  &__text-area {
    .screen__home__paragraph {
      font-size: 1rem;
      max-width: 90%;
      margin: 50px auto;

      @include media-breakpoint-up(md) {
        width: 80%;
      }

      @include media-breakpoint-up(lg) {
        width: 70%;
      }

      @include media-breakpoint-up(xl) {
        width: 60%;
      }
    }
  }
}
.screen__not-found {
  &__body {
    font-size: 32px;
    text-align: center;
    margin: 10px;

    &__404 {
      color: #dadada;
      padding: 20px;
      margin: 20px;
      font-size: 60px;
    }

    &__redirect {
      display: inline-block;
      color: $menu-text-color;
      cursor: pointer;

      &:hover {
        animation: bounce .5s normal forwards;
      }
    }
  }
}
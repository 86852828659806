.screen__about {
  padding: 0 20px;

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .screen__about__content__image {
      max-width: 100%;
      max-height: 900px;
      box-shadow: -28px -11px 0px 19px $image-shadow-color;

      @include media-breakpoint-down(lg) {
        max-height: 500px;
        margin: 0 0 20px 0;
      }

      @include media-breakpoint-down(md) {
        box-shadow: -28px 1px 0px 19px $image-shadow-color;
      }
    }

    &__text-area {
      max-width: 40%;

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      .screen__about__title {
        font-size: 1.5rem;
        max-width: 90%;
        margin: 0 auto 30px auto;
        font-weight: 600;
      }

      .screen__about__subparagraph,
      .screen__about__paragraph {
        font-size: 1rem;
        max-width: 90%;
      }

      .screen__about__paragraph {
        margin: 30px auto;
      }

      .screen__about__subparagraph {
        margin: 10px auto;
      }
    }
  }
}


// &__image-wrapper {
//   max-width: 40%;
//   position: relative;

//   @include media-breakpoint-down(md) {
//     max-width: 100%;
//     display: flex;
//     justify-content: flex-end;
//   }

//   .screen__about__content__image-background {
//     background: #3382ff;
//     width: 85%;
//     height: 700px;
//     position: absolute;
//     top: -20px;
//     bottom: -25px;
//     left: -15px;

//     @include media-breakpoint-down(xl) {
//       height: 600px;
//     }

//     @include media-breakpoint-down(lg) {
//       height: 500px;
//     }

//     @include media-breakpoint-down(md) {
//       width: 75%;
//       height: 450px;
//       left: unset;
//       right: -15px;
//     }
//   }

//   .screen__about__content__image {
//     width: 100%;
//     max-height: 900px;

//     &:hover {
//       animation: slightMove 1s infinite;
//     }

//     @include media-breakpoint-down(md) {
//       max-height: 500px;
//     }
//   }
// }
.cookies-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(#fff, .90);
  color: #000;
  text-align: center;
  padding: 15px 10px 10px 10px;

  &__text {
    &__link {
      color: $external-link-color;
      cursor: pointer;
    }
  }

  @include privacyPolicyButtons;
}
// modal overrides
.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 1;

    .ReactModal__Content {
      position: relative;

      @include media-breakpoint-down(md) {
        inset: 0 !important;
      }
    }
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.privacy-policy {
  color: #000;

  &__close {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: transparent;
    padding: 0;

    &__icon {
      width: 40px;
      height: 40px;
    }
  }

  &__title {
    text-align: center;
  }

  &__section {
    &__body {
      &__link {
        color: $external-link-color;
        text-decoration: none;
      }
    }
  }

  @include privacyPolicyButtons;
}
@mixin privacyPolicyButtons() {
  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 40px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    &__accept {
      background: #850702;
      color: #fff;
    }

    &__accept,
    &__decline {
      border: 1px solid #000;
      margin: 10px 10px 0 0;
      height: 25px;
      width: 100px;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        margin: 10px 0 0 0;
      }
    }
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(0deg);
  }
  37.5% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0);
  }
  62.5% {
    transform: rotate(2.5deg);
  }
  75% {
    transform: rotate(0deg);
  }
  87.5% {
    transform: rotate(1.25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate180 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotateAnti180 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes slightMove {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
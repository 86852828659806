.screen__faq {
  padding: 0 20px;

  .screen__faq__image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .screen__faq__image {
      width: 100%;
      box-shadow: -28px -11px 0px 19px $image-shadow-color;

      @include media-breakpoint-down(md) {
        box-shadow: -28px 1px 0px 19px $image-shadow-color;
      }
    }
  }

  &__questions {
    padding: 40px 0 0 0;

    max-width: 90%;
    margin: auto;

    .Collapsible__trigger {
      font-weight: 600;
      letter-spacing: 0.2px;
      display: flex;
      justify-content: space-between;
      color: #333;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        color: $main-text-color;
        transition: color .5s;
      }

      .screen__faq__questions__chevron--open {
        animation: rotate180 .5s normal forwards;
      }
      .screen__faq__questions__chevron--closed {
        animation: rotateAnti180 .5s normal forwards;
      }
    }

    .Collapsible {
      padding: 15px 0;
      border-bottom: 1px solid #a7a6a6;

      &:first-of-type {
        border-top: 1px solid #a7a6a6;
      }

      .Collapsible__contentInner {
        padding: 5px 0 0 0;
      }
    }
  }
}

.top-nav-link {
  text-decoration: none;
  color: $menu-text-color;
  padding: 0 15px;
  font-size: 2rem;
  cursor: pointer;
  font-family: 'Vollkorn', serif;

  @include media-breakpoint-down(md) {
    color: #e0ecff;
    padding: 10px 15px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 1.5rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: 1.4rem;
  }

  &:hover {
    color: #e0ecff;
    background: rgba($menu-text-color, 0.3);
  }

  &--active {
    color: #fff;
    background: rgba($menu-text-color, 0.4);

    @include media-breakpoint-down(md) {
      background: rgba($menu-text-color, 0.7);
    }

    &:hover {
      color: #fff;
      background: rgba($menu-text-color, 0.4);
    }
  }

  &.top-nav-link-simple {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      position: absolute;
      bottom: 10px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }
}
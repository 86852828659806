// imports
// for english fancy characters
// @import url('https://fonts.googleapis.com/css?family=Parisienne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&family=Vollkorn&display=swap');
// for greek characters
@import url('https://fonts.googleapis.com/css?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
// TODO maybe find something better so that we dont have to import the same thing every time
@import './util/variables';
@import './util/breakpoints';
@import './util/mixins';
@import 'util/keyframes';

@import './components/footer';
@import './components/top-nav';
@import './components/top-nav-link';
@import './components/cookies-banner';
@import './components/privacy-policy';
@import './components/masonry';

@import './screens/about';
@import './screens/contact';
@import './screens/home';
@import './screens/faq';
@import './screens/levels';
@import './screens/not-found';

body {
  background: $background-color;
  color: $main-text-color;
  // font-family: 'EB Garamond', serif;
  font-family: 'Source Sans Pro', sans-serif;

  height: 100vh;
  margin: 0;
  display: flex; 
  flex-direction: column; 
}

.screen {
  position: relative;
  min-height: 100vh;
}

.screen-body {
  &__main-title {
    display: block;
    text-align: center;
    font-size: 3rem;
    // font-family: 'Parisienne', cursive;
    font-family: 'Vollkorn', serif;
    font-weight: 500;
    text-shadow: 4px 4px 4px $menu-text-color;

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }
  padding-bottom: $footer-height;
}
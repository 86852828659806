.masonry__text-card-wrapper {
  display: flex;
  // margin-left: -30px; /* gutter size offset */
  width: auto;
  padding: 20px 0 0 0;

  &__column {
    // padding-left: 30px; /* gutter size */
    background-clip: padding-box;

    .masonry__text-card {
      background: #f2f2f2;
      border-radius: 5px;
      border: 0.5px solid #000;
      padding: 10px;
      overflow-y: auto;
      margin: 20px;
      box-shadow: 2px 2px 18px 0px #f2f2f2;
      text-align: center;

      @include media-breakpoint-down(md) {
        margin: 20px 10px;
      }

      &:hover {
        animation: rotate .5s normal forwards;
      }

      &__title {
        font-size: 1.5rem;
        font-weight: 600;
        margin: auto;
        // margin: 0 0 10px 0;
      }

      &__paragraph {
        font-size: 1rem;
      }
    }
  }
}
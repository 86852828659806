$sm: 480px;
// $md: 768px;
$md: 900px;
$lg: 1024px;
$xl: 1280px;

// @function get-size($size-txt){
//   // in case of lg
//   $size: $lg !default;
//   @if $size-txt == sm {
//     $size: $sm;
//   } @else if $size-txt == md {
//     $size: $md;
//   }
//   @return $size;
// }

@function get-size($size-txt){
  // in case of xl
  $size: $xl !default;
  @if $size-txt == sm {
    $size: $sm;
  } @else if $size-txt == md {
    $size: $md;
  } @else if $size-txt == lg {
    $size: $lg;
  }
  @return $size;
}

@mixin media-breakpoint-down($size-txt) {
  $size: get-size($size-txt);
  @media (max-width: #{$size - 1px}) {
    @content;
  }
}

@mixin media-breakpoint-up($size-txt) {
  $size: get-size($size-txt);
  @media (min-width: #{$size}) {
    @content;
  }
}
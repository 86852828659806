.screen__levels {
  padding: 0 20px;

  &__image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .screen__levels__image {
      width: 100%;
      box-shadow: -28px -11px 0px 19px $image-shadow-color;

      @include media-breakpoint-down(md) {
        box-shadow: -28px 1px 0px 19px $image-shadow-color;
      }
    }
  }
}

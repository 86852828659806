.screen__contact {
  &__main-area {
    display: flex;
    flex-direction: column;
    align-items: center;

    .screen__contact__content__image {
      max-width: 100%;
      max-height: 900px;
      box-shadow: -28px -11px 0px 19px $image-shadow-color;

      @include media-breakpoint-down(lg) {
        max-height: 500px;
        margin: 0 0 20px 0;
      }

      @include media-breakpoint-down(md) {
        box-shadow: -28px 1px 0px 19px $image-shadow-color;
      }
    }

    .screen__contact__contact-item-area-wrapper {
      margin: 20px 0 0 0;
      max-width: 800px;
      display: flex;

      @include media-breakpoint-down(md) {
        width: 90%;
        margin: auto;
        display: block;
      }

      .screen-body__contact-item-area {
        padding: 0 0 0 20px;

        &:first-of-type {
          border-right: 1px solid #bebebe;
          padding: 0 20px 0 0;

          @include media-breakpoint-down(md) {
            border-right: none;
            padding: 0;
          }
        }

        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }

      .screen__contact__contact-item {
        padding: 10px;
        height: 100px;
        background: #f2f2f2;
        border-radius: 3px;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
          margin-bottom: 50px;
        }

        &:hover {
          animation: bounce .5s normal forwards;
        }

        &__icon {
          width: 40px;
          height: 40px;
          margin-right: 30px;
          display: flex;
        }

        &__link {
          font-size: 1.5rem;
          text-decoration: none;
          color: $main-text-color;
          transition: 0.5s;

          &:hover {
            color: $external-link-color;
          }

          @include media-breakpoint-down(md) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
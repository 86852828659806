.footer {
  background: $menu-bg-color;
  padding: 0 0 10px 0;

  min-height: $footer-height;
  margin-top: auto; 

  &__content {
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    &__title {
      font-size: 1.5rem;
    }

    &__text {
      padding: 5px;
      font-size: 1rem;
    }

    &__creator {
      font-size: 0.75rem;
      margin: 20px 0 0 0;
    }

    &__terms {
      cursor: pointer;
      font-size: 0.75rem;
    }

    &__links {
      display: flex;
      padding: 10px 0 0 0;

      .footer__content__link {
        margin: 0 20px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $menu-text-color;
        border-radius: 50%;
        padding: 3px;
        height: 40px;
        width: 40px;
        &__img {
          width: 30px;
          height: 30px;
          color: #78b3ff;
        }
      }
    }
  }
}
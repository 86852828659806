// TODO mobile view about specific

.top-nav {
  position: sticky;
  top: 0;
  z-index: 1;

  &__links {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      background: $menu-bg-color;

      &__go-back {
        margin-right: auto;
        cursor: pointer;
        position: relative;
        width: 50px;

        &__icon {
          width: 50px;
          height: 50px;
          color: $menu-text-color;
          position: absolute;
          top: 50%;
          bottom: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          background: rgba($menu-text-color, 0.3);
          .top-nav__links__go-back__icon {
            color: #f7d7d7;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      background: rgba($menu-text-color, 0.3);

      &.top-nav__links--open {
        position: fixed;
        right: inherit;
        z-index: 1100;
        top: 0;
        width: 300px;
        height: 100%;
        transition: all 0.5s ease 0s;
        transform: translate3d(0%, 0px, 0px);

        .top-nav__links__burger-area {
          display: none;
        }
      }

      &.top-nav__links--closed {
        position: fixed;
        right: inherit;
        z-index: 1100;
        top: 0;
        width: 300px;
        height: 100%;
        transition: all 0.5s ease 0s;
        transform: translate3d(-100%, 0px, 0px);

        .top-nav__links__burger-area {
          display: block;
        }
      }

      .top-nav-link {
        display: block;
      }
    }
  }

  // this class is only applicable for mobiles
  .top-nav__empty-bg {
    width: 100vh;
    height: 100vh;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.75);
  }

  .top-nav__burger-area {
    position: fixed;
    top: 10px;
    left: 10px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // background: $menu-text-color;
    // background: $menu-bg-color;
    // border-radius: 50%;
    padding: 3px;
    height: 40px;
    width: 40px;

    @include media-breakpoint-up(md) {
      display: none;
    }

    .top-nav__burger-icon {
      width: 40px;
      height: 40px;
      cursor: pointer;
      color: $menu-bg-color;
    }
  }

  .top-nav__links__close-menu-area {
    display: flex;
    justify-content: flex-end;

    .top-nav__links__close-menu-icon {
      height: 50px;
      width: 50px;
      cursor: pointer;
      color: #e0ecff;
    }
  }
}